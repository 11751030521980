import React, { useEffect } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import {useLocation } from 'react-router-dom';

const Home = () => {

  useEffect(function () {
    Aos.init({ disable: 'mobile' });
  }, []);

  const location = useLocation();

  // useEffect(() => {
  // window.scrollTo(0, 0);
  // }, [location]);

  return (
    <>
      {/* Banner */}
      <div className="bg-grad">
        <section className="banner" id="home">
          <div className="container">
            <div className="row align-items-center text-md-start text-center">
              <div className="col-md-8 mb-md-0 mb-4" data-aos="fade-up" data-aos-duration="1800">
                <h1 className="mb-3">Welcome to <span>StratoDEX</span> </h1>
                <p className="mb-4 px-md-0 px-4">
                Creating a user-friendly trading platform with a Low fee & capital-efficient DeFi solution
                </p>
                <div className="d-flex flex-md-row flex-column gap-3 mb-lg-5 mb-4">
                <a href="javascript:void(0)" target="_blank" rel="noopener noreferrer" className='primary-btn'>Explore</a>
                </div>
                <div className="sp-img">Build on <a href="https://stratovm.io/" target={"_blank"}>
                  <img src="assets/vm.svg" className='ms-2' alt="" /></a></div>
              </div>
              
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center mb-3"  data-aos="zoom-in" data-aos-duration="1800">
                <h2 className='mb-3'>What is <span>StratoDEX?</span></h2>
                <p className='px-md-0 px-4'>A hyper-efficient decentralized crypto marketplace StratoVM</p>
              </div>
              <div className="col-md-6 mb-4">
                  <div className="dex-card"  data-aos="fade-left" data-aos-duration="1800">
                    <h5 className='mb-4'>Trade</h5>
                    <ul className='mb-4'>
                      <li>Trade any crypto on StratoVM</li>
                      <li>Best prices are offered through aggregating liquidity from our concentrated liquidity market maker algorithm Trade</li>
                    </ul>

                <a href="https://app.stratodex.io/" target="_blank" rel="noopener noreferrer" className='primary-btn'>trade</a>

                  </div>
              </div>
              <div className="col-md-6 mb-4">
                  <div className="dex-card"  data-aos="fade-right" data-aos-duration="1800">
                    <h5 className='mb-4'>Earn</h5>
                    <ul className='mb-4'>
                      <li>Provide liquidity and earn fees from trades on StratoDEX</li>
                      <li>Flexible liquidity provision modes cater users from beginner to professional levels</li>
                    </ul>

                <a href="https://app.stratodex.io/" target="_blank" rel="noopener noreferrer" className='primary-btn'>earn</a>

                  </div>
              </div>
            </div>
          </div>
        </section>
        
        </div>
        <section className='mt-5 pt-lg-5'>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center mb-3" data-aos="zoom-in" data-aos-duration="1800">
                <h2 className='mb-4'>Why <span>StratoDEX?</span></h2>
              </div>
              <div className="col-md-3 mb-4">
                  <div className="why-card"  data-aos="fade-right" data-aos-duration="1200">
                <img src="assets/w1.svg" className='mb-4' alt="" />
                    <h5>Simplest Trades, Best Prices</h5>
                  </div>
              </div>
              <div className="col-md-3 mb-4">
                  <div className="why-card" data-aos="fade-up" data-aos-duration="1400">
                <img src="assets/w2.svg" className='mb-4' alt="" />
                    <h5>Frictionless Liquidity Provision</h5>
                  </div>
              </div>
              <div className="col-md-3 mb-4">
                  <div className="why-card" data-aos="fade-up" data-aos-duration="1600">
                <img src="assets/w3.svg" className='mb-4' alt="" />
                    <h5>Discovery <br className='d-md-block d-none' /> of Market Trends</h5>
                  </div>
              </div>
              <div className="col-md-3 mb-4">
                  <div className="why-card" data-aos="fade-left" data-aos-duration="1800">
                <img src="assets/w4.svg" className='mb-4' alt="" />
                    <h5>Non- <br className='d-md-block d-none' /> custodial Platform</h5>
                  </div>
              </div>
              
            </div>
          </div>
        </section>
        
        <section className='my-5 py-lg-5' data-aos="zoom-in" data-aos-duration="1800">
          <div className="container">
            <div className="row">
              <div className="col-md-12"><h2 className='text-capitalize text-center mb-4'>Partner</h2></div>
              <div className="text-center"><a href="https://stratovm.io/" target={"_blank"}><img src="assets/vm.svg" alt="" /></a></div>
            </div>
          </div>
        </section>

       
    </>
  );
};

export default Home;
