import React from 'react';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container py-3">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center flex-md-row flex-column gap-3 justify-content-between">
                <div><img src="assets/logo.svg" alt="" /></div>
                <p className='mb-0'>Copyright © 2024 StratoDEX. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
